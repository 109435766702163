import React from "react";

export default ({ data, divClass }) => {
  return (
    <div className={divClass}>
      <video
        src={data.video && data.video.url}
        poster={data.fallbackImage && data.fallbackImage.url}
        loop
        autoPlay
        muted="true"
        playsInline
      />
    </div>
  );
};
