import React from "react";
import { graphql } from 'gatsby'
import ClientLogo from "../components/client-logo";
import MainHeader from "../components/main-header";
import Video from "../components/feature-video";
import CommonQuestion from "../components/common-question";
import StorySection from "../components/solution/story";
import { renderTestimonialSection } from "../components/home/testimonialSection";
import { resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Schema from "../components/common/schema";
import FeaturedVideo from "../components/highlight-video";
import VimeoLink from "../components/vimeo-link";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsGlobalConfiguration: globalVideo,
    datoCmsSolution: {
      seoMetaTags,
      slug,
      firstSection,
      secondSection,
      videoSectionTitle,
      videoSectionDescription,
      videos,
      thirdSection,
      schema,
      notificationPage
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow("5a6c0230513ec40001b64711");

  const video = firstSection.find(
    block => block.model && block.model.apiKey === "feature_video"
  );
  let isCustomerPage = slug.includes("customer")
  let firstSectinProps = {
    headerClass: `marketing ${isCustomerPage ? "customer" : "" }`,
    firstDivClass: "grid",
    containerClass: "hero-story-container",
    heroClass: "h-nofixedwidth",
    divClass: "story-title",
    buttonClass: `reverse ${isCustomerPage ? "tracking-video-customer": "tracking-video-marketing"}`,
    requestDemoClass: isCustomerPage ? "tracking-demo-header-customer" : "tracking-demo-header-marketing"
  };

  const firstSectionTmpl = firstSection.map(block => {
    return (
      <React.Fragment>
        {block.model && block.model.apiKey === "story_telling" && (
          <MainHeader data={block} props={firstSectinProps}>
            <div className="marketing-story">
              <Video data={video} divClass="video-home w-embed marketing" />
            </div>
          </MainHeader>
        )}
        {block.model && block.model.apiKey === "client_logo" && <ClientLogo data={block} />}
      </React.Fragment>
    );
  });
  const secondSectionHeader = secondSection.find(
    block => block.model && block.model.apiKey === "header"
  );
  const secondSectionStoryDetail = secondSection.filter(
    block => block.model && block.model.apiKey === "story_detail"
  );
  const secondSectionTmpl = secondSectionStoryDetail.map(block => {
    return (
      <div className="story-feature" key={block.id}>
        <StorySection data={block} />
      </div>
    );
  });

  const testimonialSection = thirdSection.filter(
    block => block.model && block.model.apiKey === "testimonial_detail"
  );
  const testimonialSectionTmpl = renderTestimonialSection(testimonialSection);

  const commonQuestions = thirdSection.filter(
    block => block.model && block.model.apiKey === "question"
  );

  return (
    <Layout>
      <Schema data={schema} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <HelmetDatoCms seo={seoMetaTags} />
      <div className="tint-container">
        <div className="flex-container center">
          <div id="closevid" className="close-popup" />
          <VimeoLink key={globalVideo.video.providerUid} data={globalVideo} />
        </div>
      </div>
      {firstSectionTmpl}
      <section className="white-section centered">
        <div className="container">
          <h2 className="h2">{secondSectionHeader.title}</h2>
          <div className="md-spacing" />
          <div className="flex-container space-between">
            {secondSectionTmpl}
          </div>
        </div>
      </section>
      {testimonialSectionTmpl}
      <FeaturedVideo data={videos} title={videoSectionTitle} subtitle={videoSectionDescription} />
      <section className="white-section gray">
        <div className="container">
          <CommonQuestion data={commonQuestions} />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query SolutionQuery($slug: String!) {
    datoCmsGlobalConfiguration {
      video: whatIsNugitVideo {
        providerUid
        url
      }
    }
    datoCmsSolution(slug: { eq: $slug }) {
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schema {
        ... on DatoCmsSchemaDetail {
          id
          jsonSchema
        }
      }
      firstSection {
        ... on DatoCmsStoryTelling {
          title
          subTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsClientLogo {
          logos {
            alt
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFeatureVideo {
          video {
            url
          }
          fallbackImage {
            alt
            url
          }
          model {
            apiKey
          }
        }
      }
      secondSection {
        ... on DatoCmsHeader {
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsStoryDetail {
          model {
            apiKey
          }
          title
          subtitle
          image {
            alt
            url
          }
        }
      }
      videoSectionTitle
      videoSectionDescription
      videos {
        id
        slug
        title
        subtitle
        thumbnail {
          alt
          url
        }
      }
      thirdSection {
        ... on DatoCmsTestimonialDetail {
          id
          text
          user
          userRole
          userAvatar {
            alt
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsQuestion {
          question
          answer
          model {
            apiKey
          }
        }
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
