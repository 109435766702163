import React from 'react'

export default ({ data }) => {
    return (
        <React.Fragment>
            <div className="illustration no-margin">
                <img src={data.image.url} alt={data.image.alt}/>
            </div>
            <div className="story-feature-content">
                <h4 className="h4">{data.title}</h4>
                <div className="sm-spacing" />
                <div className="small-copy">{data.subtitle}</div>
            </div>
        </React.Fragment>
    );
};